export const locale = {
    GENERAL: {
      NAV_OPTIONS: ['Home', 'About', 'Contact'],
      WELCOME_WORD: '网站欢迎你造访',
      OK: 'ok',
      CONTINUE: 'continue',
      CANCEL: 'cancel',
      GUEST: 'guest',
      OR: '或'
    },
    AUTH: {
      SIGN_IN: '登入',
      SIGN_UP: '注册',
      CREATE_YOUR_ACCOUNT: '註册新帳戶',
      SIGNIN_TO_YOUR_ACCOUNT: '登入你的帳戶',
      SIGNUP_NEW_ACCOUNT: '注册一个新帐号',
      EMAIL_ADDRESS: '电子邮件地址',
      PASSWORD: '密码',
      CONFIRM_PASSWORD: '验证密码',
      RESET_PASSWORD: '重设你的密码',
      REQUEST_RESET: '要求重设密码',
      REQUEST_SUCCESS: '要求成功送出',
      REQUEST_FAIL: '要求失败',
      USERNAME: '使用者名称',
      REG_SUCCESS_TITLE: '帐户注册成功',
      WAIT_REG_PROCESS: '请稍待，资料正在处理中',
      REG_FAIL_TITLE: '帐户注册失败'
    },
    FORM: {
      DATA_REQUIRED : '资料栏位不能留空'
    }
  }