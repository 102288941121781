import { createStore } from 'vuex'

import axios from 'axios';
import createPersistedState from 'vuex-persistedstate'
import router from '@/router/index';
// import config from "@/common/config/config"

export default createStore({
  state: {
    userLoggedIn: false,
    accessToken: null,
    responseMessage: null,
    regMsgShow: false,
  },
  getters: {
    accessToken: (state) => state.accessToken,
    userLoggedIn: (state) => state.userLoggedIn,
  },
  mutations: {
    toggleAuth(state) {
      state.userLoggedIn = ! state.userLoggedIn;
    },
    updateToken(state,token) {
      state.accessToken = token;
    },
    clearToken(state) {
      state.accessToken = null;
    },
    responseMsg(state, message) {
      state.responseMessage = message;
    },
    regMsgShow(state, resp_status) {
      state.regMsgShow = resp_status;
    }
  },
  actions: {
    async helloWorld() {
      // const host = process.env.VUE_APP_URL + '/api';
      const host = process.env.VUE_APP_API_URL + '';
      console.log(host)
      const headers = {
        'Accept-Language': localStorage.getItem('language')
      }
      axios.get(`${ host }`, {
        headers: headers
      }).then( resp => {
        console.log(resp)
      }).catch(error => {
        if (error.request.status==401) {
          console.log(error.request.status)
          this.dispatch('signout');
          // router.push({name:'sign-in'});
        } 
        console.log(error)
      });

    },
    async reloadReg({ commit }) {
      commit('regMsgShow',false); 
      commit('responseMsg',''); 
    },
    async register({ commit }, payload) {
      // const host = config.HOST;
      // const host = window.location.origin;
      const host = process.env.VUE_APP_API_URL + '';
      const headers = {
        'Accept-Language': localStorage.getItem('language')
      }
      let regData = new FormData();
      regData.append('username', payload.username);
      regData.append('email', payload.email);
      regData.append('password', payload.password);
      axios.post(`${ host }/register`, regData, {
        headers: headers
      }).then( resp => {
        commit('responseMsg',resp.data['message']); 
        commit('regMsgShow', true);
      }).catch(error => {
        console.log(error.response.data['detail']);
        commit('responseMsg',error.response.data['detail']); 
        commit('regMsgShow', false);
      });
    },
    async active_account({ commit }, active_token) {
      // const host = config.HOST;
      // const host = window.location.origin;
      const host = process.env.VUE_APP_API_URL + '';
      const headers = {
        'Accept-Language': localStorage.getItem('language')
      }
      axios.get(`${ host }/active-account/${ active_token }`, {
        headers: headers
      }).then( resp => {
        commit('responseMsg',resp.data['message']); 
        // commit('regMsgShow', true);
      }).catch(error => {
        console.log(error.response.data['detail']);
        commit('responseMsg',error.response.data['detail']); 
        // commit('regMsgShow', false);
      });
      console.log('store token:',active_token);
      let resp_msg = 'active account response message';
      commit('responseMsg',resp_msg);
    },
    async login({ commit }, payload) {
      console.log('login state:',process.env.VUE_APP_API_URL)
      // const host = config.HOST;
      // const host = window.location.origin;
      const host = process.env.VUE_APP_API_URL + '';
      let loginData = new FormData();
      loginData.append('username', payload.email);
      loginData.append('password', payload.password);
      let header_config = {
        'Content-Type' : 'multipart/form-data',
        'Accept-Language': localStorage.getItem('language')
      }

      await axios.post(`${ host }/token`, loginData, {
        headers: header_config
      }).then( resp => {
        if (resp.status == 200) {
          commit('toggleAuth');
          commit('updateToken',resp.data.access_token);
        } 
      });
    },
    async signout({ commit }) {
      commit('toggleAuth'); 
      commit('clearToken'); 
    },
    async request_reset_password({ commit }, payload) {
      // const host = config.HOST;
      // const host = window.location.origin;
      const host = process.env.VUE_APP_API_URL + '';
      let requestData = new FormData();
      requestData.append('email', payload.email);
      let header_config = {
        'Content-Type' : 'multipart/form-data',
        'Accept-Language': localStorage.getItem('language')
      }

      await axios.post(`${ host }/request-reset-password`, requestData, {
        headers: header_config
      }).then( resp => {
        if (resp.status == 200) {
          commit('regMsgShow', true);
          commit('responseMsg',resp.data['message']);
        } 
      }).catch(error => {
        console.log(error.response.data['detail']);
        commit('regMsgShow', true);
        commit('responseMsg',error.response.data['detail']); 
      });
    },
    async reset_password({ commit }, payload) {
      // const host = config.HOST;
      // const host = window.location.origin;
      const host = process.env.VUE_APP_API_URL + '';
      let resetData = new FormData();
      resetData.append('password', payload.password);
      let header_config = {
        'Content-Type' : 'multipart/form-data',
        'Accept-Language': localStorage.getItem('language')
      }

      await axios.post(`${ host }/reset-password/${ payload.token }`, resetData, {
        headers: header_config
      }).then( resp => {
        if (resp.status == 200) {
          commit('regMsgShow', true);
          commit('responseMsg',resp.data['message']);
        } 
      }).catch(error => {
        console.log(error.response.data['detail']);
        commit('regMsgShow', true);
        commit('responseMsg',error.response.data['detail']); 
      });
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
