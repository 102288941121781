
<template>
  <div v-if="this.$store.state.regMsgShow" class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="pt-6 text-center text-3xl font-extrabold {{ this.reg_title_color }} ">{{  this.reg_alert_title }}</h2>
      <p class="mt-4">{{ this.$store.state.responseMessage }}</p>
      <div class="pt-6 ">
        <router-link to="/sign-in" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{{ $t('AUTH.SIGN_IN')}}</router-link>
      </div>
    </div>
  </div>

  <div v-if="!this.$store.state.regMsgShow"  class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ $t('AUTH.RESET_PASSWORD')}}</h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        {{ $t('GENERAL.OR')}}
        {{ ' ' }}
        <router-link to="/sign-in" class="font-medium text-indigo-600 hover:text-indigo-500"> {{ $t('AUTH.SIGNIN_TO_YOUR_ACCOUNT')}} </router-link>
      </p>
    </div>

    <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 sm:px-10">
        <p class="text-red-400 text-sm pb-4">
            {{ this.$store.state.responseMessage }}
        </p>
        <vee-form class="space-y-2" 
                :validation-schema="schemaRequest"
                @submit="request_reset_password">

          <vee-field name="email" :bails="false" v-slot="{ field, errors }">
            <label for="email" class="block text-sm font-medium text-gray-700"> {{ $t('AUTH.EMAIL_ADDRESS' )}} </label>
            <div class="mt-1">
              <input type="email" 
                    autocomplete="email" 
                    placeholder="Your Email"
                    v-bind="field" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm" />
            </div>
            <div class="text-red-400 text-sm" v-for="error in errors" :key="error">
                {{ error }}
            </div>
          </vee-field>

          <div class="pt-6 ">
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{{ $t('AUTH.REQUEST_RESET')}}</button>
          </div>
        </vee-form>

      </div>
    </div>
  </div>
</template>

<script>
// import store from '@/store';

export default {
    name: 'ResetPassword',
    data() {
        return {
            location: location.toString(),
            host: location.protocol.toString()+'//'+location.host.toString()+'/',
            regDisplay: false,
            schemaRequest: {
                email: 'required|min:8|max:100|email',
            },
            reg_in_submission: false,
            reg_show_alert: false,
            reg_alert_title: '',
            reg_title_color: 'text-gray-900',
            reg_alert_msg: '',
            reg_alert_msg_id: 0,
        }
    },
    methods: {
        async request_reset_password(values) {
            try {
                console.log('request reset')
                await this.$store.dispatch('request_reset_password', values);
                if(this.$store.getters.regMsgShow) {
                  this.reg_alert_title = this.$t('AUTH.REQUEST_SUCCESS');
                }
            } catch (error) {
              console.log(error)
            }

        },
        async reload() {
          await this.$store.dispatch('reloadReg');
          this.reg_alert_msg = '';
        }
    },
    created() {
        this.reload();
        if(this.$store.state.userLoggedIn) {
          this.$router.push({ name: 'dashboard' })
        }
    }
}
</script>
