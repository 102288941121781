<template>
    <transition>
        <div v-show="display" class="z-50 fixed w-full h-full inset-0 bg-gray-900 flex flex-col items-center justify-center">
            <img class="animate-spin w-8" src="/img/loading.png">
            <p class="pt-4 text-gray-400 text-sm animate-pulse">Loading ...</p>

        </div>
    </transition>
</template>

<script>
export default ({
    name: 'loadingImage',
    props: ['display']
})
</script>

<style>
    .v-enter-active, .v-leave-active {
        transition: opacity 1s;
    }

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }

    .v-enter-to, .v-leave-from {
        opacity: 1;
    }
</style>
