
<template>
  <div v-if="this.$store.state.regMsgShow" class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ this.$store.state.responseMessage }}</h2>
      <div class="pt-6 ">
        <router-link to="/sign-in" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{{ $t('AUTH.SIGN_IN')}}</router-link>
      </div>
    </div>
  </div>


  <div v-if="!this.$store.state.regMsgShow" class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ $t('AUTH.RESET_PASSWORD')}}</h2>
    </div>

    <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 sm:px-10">
        <p class="text-red-400 text-sm pb-4">
            {{ this.$store.state.responseMessage }}
        </p>
        <vee-form class="space-y-2" 
                :validation-schema="schemaReset"
                :initial-values="userData"
                @submit="reset_password">

          <vee-field name="token" :bails="false" v-slot="{ field }">
              <input type="hidden"
                    v-bind="field" />
          </vee-field>

          <vee-field name="password" :bails="false" v-slot="{ field, errors }">
            <label for="password" class="block text-sm font-medium text-gray-700 pt-6"> {{ $t('AUTH.PASSWORD' )}} </label>
            <div class="mt-1">
              <input type="password" 
                    placeholder="Password"
                    autocomplete="current-password" 
                    v-bind="field" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm" />
            </div>
            <div class="text-red-400 text-sm" v-for="error in errors" :key="error">
                {{ error }}
            </div>
          </vee-field>

          <vee-field name="confirm_password" :bails="false" v-slot="{ field, errors }">
            <label for="confirm_password" class="block text-sm font-medium text-gray-700 pt-6"> {{ $t('AUTH.CONFIRM_PASSWORD' )}} </label>
            <div class="mt-1">
              <input type="password" 
                    placeholder="Confirm Password"
                    autocomplete="current-password" 
                    v-bind="field" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm" />
            </div>
            <div class="text-red-400 text-sm" v-for="error in errors" :key="error">
                {{ error }}
            </div>
          </vee-field>

          <div class="pt-6 ">
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{{ $t('AUTH.SIGN_IN')}}</button>
          </div>
        </vee-form>

      </div>
    </div>
  </div>
</template>

<script>
// import store from '@/store';
// import config from "@/common/config/config"

export default {
    name: 'ResetPassword',
    data() {
        return {
            schemaReset: {
                password: 'required|min:5|max:100',
                confirm_password: 'confirmed:@password',
            },
            userData: {
                token: this.$route.params.reset_token,
            }
        }
    },
    methods: {
        async reset_password(values) {
          try {
              await this.$store.dispatch('reset_password', values);
          } catch (error) {
              console.log(error)
          }

        },
        async reload() {
          await this.$store.dispatch('reloadReg');
          this.reg_alert_msg = '';
        }
    },
    created() {
        this.reload();
    }
}
</script>
