import { createI18n } from 'vue-i18n'

import { locale as en } from '@/common/config/i18n/en'
import { locale as zh } from '@/common/config/i18n/zh'
import { locale as cn } from '@/common/config/i18n/cn'

let messages = {}
messages = { ...messages, en, zh, cn }

const lang = localStorage.getItem('language') || 'en'

const i18n = createI18n({
  locale: lang,
  messages
})

export default i18n