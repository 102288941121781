
<template>
  <div  class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ this.$store.state.responseMessage }}</h2>
      <div class="pt-6 ">
        <router-link to="/sign-in" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{{ $t('AUTH.SIGN_IN')}}</router-link>
      </div>
    </div>

  </div>
</template>

<script>
// import store from '@/store';
// import config from "@/common/config/config"

export default {
    name: 'ActiveAccount',
    data() {
        return {
            active_token: this.$route.params.active_token,
        }
    },
    methods: {
        async active_account(values) {

            try {
                await this.$store.dispatch('active_account', values);
            } catch (error) {
                console.log(error)
            }

        },
        async reload() {
          await this.$store.dispatch('reloadReg');
          this.reg_alert_msg = '';
        }
    },
    created() {
        this.active_account(this.active_token)
    }
}
</script>
