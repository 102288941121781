export const locale = {
    GENERAL: {
      NAV_OPTIONS: ['Home', 'About', 'Contact'],
      WELCOME_WORD: '歡迎您的到來。',
      OK: 'ok',
      CONTINUE: 'continue',
      CANCEL: 'cancel',
      GUEST: 'guest',
      OR: '或'
    },
    AUTH: {
      SIGN_IN: '登入',
      SIGN_UP: '註冊',
      CREATE_YOUR_ACCOUNT: '註冊新帳戶',
      SIGNIN_TO_YOUR_ACCOUNT: '登入您的帳戶',
      SIGNUP_NEW_ACCOUNT: '註冊一個新帳號',
      EMAIL_ADDRESS: '電子郵件地址',
      PASSWORD: '密碼',
      CONFIRM_PASSWORD: '驗證密碼',
      RESET_PASSWORD: '重設您的密碼',
      REQUEST_RESET: '要求重設密碼',
      REQUEST_SUCCESS: '要求成功送出',
      REQUEST_FAIL: '要求失敗',
      USERNAME: '使用者名稱',
      REG_SUCCESS_TITLE: '帳戶註冊成功',
      WAIT_REG_PROCESS: '請稍侯，您的資料正在處理中',
      REG_FAIL_TITLE: '帳戶註冊失敗'
    },
    FORM: {
      DATA_REQUIRED : '資料欄位為必填'
    }
  }