import { Field as VeeField, Form as VeeForm, defineRule, ErrorMessage, configure } from 'vee-validate';
import { required, min, max, email, confirmed } from '@vee-validate/rules';

export default {
    install(app) {
        app.component('VeeForm', VeeForm);
        app.component('VeeField', VeeField);
        app.component('ErrorMessage', ErrorMessage);

        defineRule('required', required);
        defineRule('min', min);
        defineRule('max', max);
        defineRule('email', email);
        defineRule('confirmed', confirmed);

        configure({
            generateMessage: (ctx) => {
                // const messages = {
                //     required: `${ctx.field}資料欄位為必填`,
                //     min: `${ctx.field}資料欄位的資料內容長度不足`,
                //     max: `${ctx.field}資料欄位的資料內容長度超過限制`,
                //     email: `${ctx.field}資料欄位的格式錯誤`,
                //     confirmed: `${ctx.field}資料不符合`,
                // };
                const messages = {
                    required: `Field can't be blank.`,
                    min: `Data is too short.`,
                    max: `Data is too long.`,
                    email: `Wrong email format.`,
                    confirmed: `Password not match.`,
                };
                const message = messages[ctx.rule.name] ? messages[ctx.rule.name] : `Data error ${ctx.field}`;

                return message;
            },
            validateOnBlur: true,
            validateOnChange: true,
            validateOnInput: false,
            validateOnModelUpdate: true,
        })
    }
};