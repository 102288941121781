<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Popover class="relative bg-slate-100">
    <div class="flex justify-between items-center px-4 py-3 sm:px-6 md:justify-start md:space-x-10">
      <div>
        <router-link to="/" class="flex">
          <span class="sr-only">Z4 Auth Demo</span>
          <img class="h-8 w-auto sm:h-10" src="/img/logo.png" alt="" />
        </router-link>
      </div>
      <div class="-mr-2 -my-2 md:hidden">
        <PopoverButton class="bg-slate-100 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
          <span class="sr-only">Open menu</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </PopoverButton>
      </div>
      <div class="hidden md:flex-1 md:flex md:items-center md:justify-between">
        <PopoverGroup as="nav" class="flex space-x-10">
          <!--<Popover class="relative" v-slot="{ open }">
            <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span>Solutions</span>
              <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                    <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                      <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="p-5 bg-gray-50 sm:p-8">
                    <a href="#" class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                      <div class="flex items-center">
                        <div class="text-base font-medium text-gray-900">Enterprise</div>
                        <span class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"> New </span>
                      </div>
                      <p class="mt-1 text-sm text-gray-500">Empower your entire team with even more advanced tools.</p>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
          <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900"> Pricing </a>
          <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900"> Docs </a>

          <Popover class="relative" v-slot="{ open }">
            <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span>More</span>
              <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <a v-for="item in resources" :key="item.name" :href="item.href" class="-m-3 p-3 block rounded-md hover:bg-gray-50">
                      <p class="text-base font-medium text-gray-900">
                        {{ item.name }}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                        {{ item.description }}
                      </p>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>-->
        </PopoverGroup> 
        <div class="flex items-center md:ml-12">
          <router-link to="/sign-in" class="text-base font-medium text-slate-600 hover:text-slate-900"> {{ $t('AUTH.SIGN_IN')}} </router-link>
          <router-link to="/sign-up" class="ml-6 text-base font-medium text-slate-600 hover:text-slate-900"> {{ $t('AUTH.SIGN_UP')}} </router-link>
          
          <select id="language" name="language" @change="onch($event)" v-model="selected" class="ml-6 block pl-3 pr-10 py-1 text-base border-gray-300 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm rounded-md">
            <option v-for="option in languageOptions" :key="option.title" :value="option.value" >{{ option.description }}</option>
          </select>
          
          <!--
          <Listbox as="div" v-model="selected" @change="onch">
            <ListboxLabel class="sr-only"> Change language </ListboxLabel>
            <div class="relative">
            <div class="ml-6 inline-flex shadow-sm rounded-md divide-x divide-slate-600">
                <div class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-slate-600">
                <div class="relative inline-flex items-center bg-slate-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                    <TranslateIcon class="h-5 w-5" aria-hidden="true" />
                    <p class="ml-2.5 text-sm font-medium">{{ selected.title }}</p>
                </div>
                <ListboxButton class="relative inline-flex items-center bg-slate-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-slate-500">
                    <span class="sr-only">Change language</span>
                    <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </ListboxButton>
                </div>
            </div>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ListboxOption as="template" v-for="option in languageOptions" :key="option.title" :value="option" v-slot="{ active, selected }">
                    <li :class="[active ? 'text-white bg-slate-500' : 'text-gray-900', 'cursor-default select-none relative p-4 text-sm']">
                    <div class="flex flex-col">
                        <div class="flex justify-between">
                        <span :class="selected ? 'font-semibold' : 'font-normal'">
                            {{ option.title }}
                        </span>
                        <span :class="[active ? 'text-slate-200' : 'text-gray-500']">
                            {{ option.description }}
                        </span>
                        <span v-if="selected" :class="active ? 'text-white' : 'text-slate-500'">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </div>
                    </div>
                    </li>
                </ListboxOption>
                </ListboxOptions>
            </transition>
            </div>
        </Listbox>
        -->
        </div>
      </div>
    </div>

    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <PopoverPanel focus class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-slate-100 divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="img/logo.png" alt="Workflow" />
              </div>
              <div class="-mr-2">
                <PopoverButton class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-6">
                <router-link v-for="item in solutions" :key="item.name" :to="item.href" class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                  <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-slate-500 text-white">
                    <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    {{ item.name }}
                  </div>
                </router-link>
              </nav>
            </div>
          </div>
          <div class="py-6 px-5">
            <div class="w-full">
              <select id="language" name="language" @change="onch($event)" v-model="selected" class="w-full block pl-3 pr-10 py-1 text-base border-slate-500 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm rounded-md">
                <option v-for="option in languageOptions" :key="option.title" :value="option.value" >{{ option.description }}</option>
              </select>
              <!--
              <Listbox as="div" v-model="selected" >
                  <ListboxLabel class="sr-only"> Change language </ListboxLabel>
                  <div class="relative">
                  <div class="w-full inline-flex shadow-sm rounded-md divide-x divide-slate-600">
                      <div class="w-full relative z-0 inline-flex shadow-sm rounded-md divide-x divide-slate-600">
                      <div class="grow relative inline-flex items-center bg-slate-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                          <TranslateIcon class="h-5 w-5" aria-hidden="true" />
                          <p class="ml-2.5 text-sm font-medium">{{ selected.title }}</p>
                      </div>
                      <ListboxButton class="relative inline-flex items-center bg-slate-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-slate-500">
                          <span class="sr-only">Change language</span>
                          <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                      </ListboxButton>
                      </div>
                  </div>

                  <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                      <ListboxOptions class="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <ListboxOption as="template" v-for="option in languageOptions" :key="option.title" :value="option" v-slot="{ active, selected }">
                          <li :class="[active ? 'text-white bg-slate-500' : 'text-gray-900', 'cursor-default select-none relative p-4 text-sm']">
                          <div class="flex flex-col">
                              <div class="flex justify-between">
                              <span :class="selected ? 'font-semibold' : 'font-normal'">
                                  {{ option.title }}
                              </span>
                              <span :class="[active ? 'text-slate-200' : 'text-gray-500']">
                                  {{ option.description }}
                              </span>
                              <span v-if="selected" :class="active ? 'text-white' : 'text-slate-500'">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                              </div>
                          </div>
                          </li>
                      </ListboxOption>
                      </ListboxOptions>
                  </transition>
                  </div>
              </Listbox>
              -->
            </div>
            <div class="mt-6">
              <router-link to="/sign-in" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-normal text-white bg-slate-600 hover:bg-slate-700"> {{ $t('AUTH.SIGNIN_TO_YOUR_ACCOUNT')}} </router-link>        
              <p class="mt-2 text-center text-sm text-gray-600">
                {{ $t('GENERAL.OR')}}
                {{ ' ' }}
                <router-link to="/sign-up" class="font-medium text-indigo-600 hover:text-indigo-500"> {{ $t('AUTH.SIGNUP_NEW_ACCOUNT')}} </router-link>
              </p>
              <p class="mt-2 text-center text-sm text-gray-600">
                {{ $t('GENERAL.OR')}}
                {{ ' ' }}
                <router-link to="/reqeust-reset-password" class="font-medium text-indigo-600 hover:text-indigo-500"> {{ $t('AUTH.RESET_PASSWORD')}} </router-link>
              </p>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
// import { ref } from 'vue'
// import { Popover, PopoverButton, PopoverGroup, PopoverPanel, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  HomeIcon,
  MenuIcon,
  XIcon,
  // TranslateIcon,
} from '@heroicons/vue/outline'
// import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'

const languageOptions = [
  { title: 'EN', description: 'English', current: true, value:'en' },
  { title: 'ZH', description: '繁體中文', current: false, value:'zh' },
  { title: 'CN', description: '简体中文', current: false, value:'cn' },
]

// const lang = localStorage.getItem('language')
// let option_index = 0
// if (lang) {
//     for(let i=0; i<languageOptions.length; i++) {
//         if(languageOptions[i].value==lang) {
//            option_index = i; 
//         }
//     }
// }
/*
const selected = ref(languageOptions[option_index])
*/
const solutions = [
  {
    name: 'Home',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '/',
    icon: HomeIcon,
  },
]


</script>

<script>
export default {
  data () {
    return {
      selected: localStorage.language,
    }
  },
  methods: {
    setLang (evt) {
      const lang = evt.target.dataset.lang;
      this.setActiveLanguage(lang);
      return history.go(0);
    },
    onch(e) {
      // console.log('change',e);
      console.log('change',e.target.value);
      localStorage.setItem('language', e.target.value);
      history.go(0);
    }
  },
  mounted() {
    if(!localStorage.language) localStorage.setItem('language','en');
    // this.$store.dispatch('helloWorld');
  },
  // watch: {
  //     selected: function(v) {
  //         console.log(v.value);
  //         localStorage.setItem('language', v.value);

  //         // setTimeout(() => {
  //         //   console.log("Delayed for 1 second.");
  //         //   history.go(0);
  //         // }, "2000")
  //         history.go(0);
  //     }
  //   selected: {
  //     handler(val) {
  //       console.log(val)
  //       localStorage.setItem('language', val.value);
  //       history.go(0);
  //     },
  //     deep: true
  //   }
  // },
}
</script>