<template>
    <NAHeader />
    <div class="bg-gradient-to-t from-slate-100 via-pink-200 to-indigo-400">
    <div class="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
      <img src="/img/404.png">
      <p class="mt-2 text-2xl leading-6 text-gray-800">Sorry, the page you requested is not found.</p>
    </div>
  </div>
  </template>
  
  <script>
  import NAHeader from '@/components/NAHeader.vue'
  // import SignIn from '@/components/Auth/SignIn.vue'
  
  export default {
    name: 'PageNotFoundView',
    components: {
      NAHeader
    }
  }
  </script>
  