<template>
  <NAHeader />
  <div class="Auth">
    <ResetPassword />
  </div>
</template>

<script>
import NAHeader from '@/components/NAHeader.vue'
import ResetPassword from '@/components/Auth/ResetPassword.vue'

export default {
  name: 'HomeView',
  components: {
    NAHeader,
    ResetPassword
  }
}
</script>
