import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import i18n from './common/plugins/vue-i18n'
import VeeValidatePlugin from './common/plugins/validation'
import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp(App);
app.use(i18n);
app.use(store);
app.use(router);
app.use(VeeValidatePlugin);
app.use(VueAxios,axios);
app.use( CKEditor );
app.mount('#app');