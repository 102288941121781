import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import SignUpView from '../views/Auth/SignUpView.vue'
import SignInView from '../views/Auth/SignInView.vue'
import ActiveAccountView from '../views/Auth/ActiveAccountView.vue'
import RequestResetPasswordView from '../views/Auth/RequestResetPasswordView.vue'
import ResetPasswordView from '../views/Auth/ResetPasswordView.vue'
import PageNotFoundView from '../views/Basic/PageNotFoundView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    redirect: { name: 'sign-in' }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpView
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInView
  },
  {
    path: '/active-account/:active_token',
    name: 'active-account',
    component: ActiveAccountView
  },
  {
    path: '/reqeust-reset-password',
    name: 'reqeust-resset-password',
    component: RequestResetPasswordView
  },
  {
    path: '/reset-password/:reset_token',
    name: 'resset-password',
    component: ResetPasswordView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account/AccountView.vue'),
    meta: { requireAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Account/DashboardView.vue')
      },
      {
        path: 'team/list',
        name: 'team-list',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Team/TeamListView.vue')
      },
      {
        path: 'general',
        component: () => import(/* webpackChunkName: "account" */ '../views/Basic/BasicView.vue'),
        children: [
          {
            path: 'settings',
            name: 'general-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Basic/GeneralSettingView.vue')
          },
        ]
      },
      {
        path: 'homepage',
        component: () => import(/* webpackChunkName: "account" */ '../views/Homepage/HomepageView.vue'),
        children: [
          {
            path: 'settings',
            name: 'homepage-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Homepage/HomepageSettingView.vue')
          },
        ]
      },
      {
        path: 'about-us',
        component: () => import(/* webpackChunkName: "account" */ '../views/AboutUs/AboutUsView.vue'),
        children: [
          {
            path: 'settings',
            name: 'about-us-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/AboutUs/AboutUsSettingView.vue')
          },
        ]
      },
      {
        path: 'contact',
        component: () => import(/* webpackChunkName: "account" */ '../views/Contact/ContactView.vue'),
        children: [
          {
            path: 'settings',
            name: 'contact-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Contact/ContactSettingView.vue')
          },
        ]
      },
      {
        path: 'news',
        component: () => import(/* webpackChunkName: "account" */ '../views/News/NewsView.vue'),
        children: [
          {
            path: 'settings',
            name: 'news-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/News/NewsSettingView.vue')
          },
        ]
      },
      {
        path: 'portfolio',
        component: () => import(/* webpackChunkName: "account" */ '../views/Portfolio/PortfolioView.vue'),
        children: [
          {
            path: 'settings',
            name: 'portfolio-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Portfolio/PortfolioSettingView.vue')
          },
        ]
      },
      {
        path: 'recruit',
        component: () => import(/* webpackChunkName: "account" */ '../views/Recruit/RecruitView.vue'),
        children: [
          {
            path: 'settings',
            name: 'recruit-setting',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Recruit/RecruitSettingView.vue')
          },
        ]
      },
      {
        path: 'edit/page-info/:id',
        name: 'edit-page-info',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPageInfoView.vue')
      },
      {
        path: 'edit/page-meta/:page_id/:meta_id',
        name: 'edit-page-meta',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPageMetaView.vue')
      },
      {
        path: 'edit/page-content/:page_id/:content_id/:resource_id',
        name: 'edit-page-content',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPageContentView.vue')
      },
      {
        path: 'edit/page-link/:page_id/:content_id/:resource_id',
        name: 'edit-page-link',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPageLinkView.vue')
      },
      {
        path: 'edit/page-text/:page_id/:content_id/:resource_id',
        name: 'edit-page-text',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPageTextView.vue')
      },
      {
        path: 'edit/content-image/:page_id/:content_id',
        name: 'edit-content-image',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditContentImageView.vue')
      },
      {
        path: 'edit/news-image/:type/:id',
        name: 'edit-news-image',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditNewsImageView.vue')
      },
      {
        path: 'edit/news-text/:type/:category/:id',
        name: 'edit-news-text',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditNewsView.vue')
      },
      {
        path: 'edit/portfolio-category-image/:id',
        name: 'edit-portfolio-category-image',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPortfolioCategoryImageView.vue')
      },
      {
        path: 'edit/portfolio-category-text/:id',
        name: 'edit-portfolio-category-text',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPortfolioCategoryTextView.vue')
      },
      {
        path: 'portfolio/list/:category_id',
        name: 'portfolio-list',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Portfolio/PortfolioListView.vue')
      },
      {
        path: 'edit/portfolio-text/:category_id/:id',
        name: 'edit-portfolio-text',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPortfolioView.vue')
      },
      {
        path: 'edit/portfolio-image/:category_id/:id',
        name: 'edit-portfolio-image',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPortfolioImageView.vue')
      },
      {
        path: 'edit/portfolio-tag/:portfolio_id/:tag_id',
        name: 'edit-portfolio-tag',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPortfolioTagView.vue')
      },
      {
        path: 'edit/portfolio-detail/:portfolio_id/:detail_id',
        name: 'edit-portfolio-detail',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditPortfolioDetailView.vue')
      },
      {
        path: 'media',
        component: () => import(/* webpackChunkName: "account" */ '../views/Media/MediaView.vue'),
        children: [
          {
            path: 'list',
            name: 'media-list',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Media/MediaListView.vue')
          },
        ]
      },
      {
        path: 'edit/media',
        name: 'edit-media',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Edit/EditMediaView.vue')
      },
    ]
  },
  {
    path: '/no-privilege',
    name: 'no-privilege',
    component: () => import(/* webpackChunkName: "no-privilege" */ '../views/Basic/NoPriviledgeView.vue')
  },
  {
    // path: "*",
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: PageNotFoundView
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
  history: createWebHistory(),
  routes
})

// check login status
router.beforeEach(async(to, from, next) => {
  // console.log('to: ', to)
  // console.log('from: ', from)
  // console.log(store.getters.userLoggedIn)

  // store.dispatch('helloWorld');

  if (to.meta.requireAuth) {
    if (store.getters.userLoggedIn) {
        next()
    } else {
      next({ name: 'sign-in' })
    }
  } else {
    next()
  }
})

export default router
