<template>
  <NAHeader />
  <div class="Auth">
    <RequestResetPassword />
  </div>
</template>

<script>
import NAHeader from '@/components/NAHeader.vue'
import RequestResetPassword from '@/components/Auth/RequestResetPassword.vue'

export default {
  name: 'ResetPasswordView',
  components: {
    NAHeader,
    RequestResetPassword
  }
}
</script>
