<template>
  <NAHeader />
  <div class="Auth">
    <ActiveAccount />
  </div>
</template>

<script>
import NAHeader from '@/components/NAHeader.vue'
import ActiveAccount from '@/components/Auth/ActiveAccount.vue'

export default {
  name: 'HomeView',
  components: {
    NAHeader,
    ActiveAccount
  }
}
</script>
