
<template>
  <div  class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ $t('AUTH.SIGNIN_TO_YOUR_ACCOUNT')}}</h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        {{ $t('GENERAL.OR')}}
        {{ ' ' }}
        <router-link to="/sign-up" class="font-medium text-indigo-600 hover:text-indigo-500"> {{ $t('AUTH.SIGNUP_NEW_ACCOUNT')}} </router-link>
      </p>
      <p class="mt-2 text-center text-sm text-gray-600">
        {{ $t('GENERAL.OR')}}
        {{ ' ' }}
        <router-link to="/reqeust-reset-password" class="font-medium text-indigo-600 hover:text-indigo-500"> {{ $t('AUTH.RESET_PASSWORD')}} </router-link>
      </p>
    </div>

    <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 sm:px-10">
        <p class="text-red-400 text-sm pb-4">
            {{ this.$store.state.responseMessage }}
        </p>
        <vee-form class="space-y-2" 
                :validation-schema="schemaLogin"
                @submit="signin">

          <vee-field name="email" :bails="false" v-slot="{ field, errors }">
            <label for="email" class="block text-sm font-medium text-gray-700"> {{ $t('AUTH.EMAIL_ADDRESS' )}} </label>
            <div class="mt-1">
              <input type="email" 
                    autocomplete="email" 
                    placeholder="Your Email"
                    v-bind="field" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm" />
            </div>
            <div class="text-red-400 text-sm" v-for="error in errors" :key="error">
                {{ error }}
            </div>
          </vee-field>

          <vee-field name="password" :bails="false" v-slot="{ field, errors }">
            <label for="password" class="block text-sm font-medium text-gray-700 pt-6"> {{ $t('AUTH.PASSWORD' )}} </label>
            <div class="mt-1">
              <input type="password" 
                    placeholder="Password"
                    autocomplete="current-password" 
                    v-bind="field" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm" />
            </div>
            <div class="text-red-400 text-sm" v-for="error in errors" :key="error">
                {{ error }}
            </div>
          </vee-field>

          <div class="pt-6 ">
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{{ $t('AUTH.SIGN_IN')}}</button>
          </div>
        </vee-form>

      </div>
    </div>
  </div>
  <loading :display="loadDisplay"></loading>
</template>

<script>
// import store from '@/store';
import loading from '@/components/Basic/LoadingImage.vue';

export default {
    name: 'SignIn',
    components: {
      loading
    },  
    data() {
        return {
            loadDisplay: false,
            location: location.toString(),
            host: location.protocol.toString()+'//'+location.host.toString()+'/',
            regDisplay: false,
            schemaLogin: {
                email: 'required|min:8|max:100|email',
                password: 'required|min:5|max:100',
            },
            reg_in_submission: false,
            reg_show_alert: false,
            reg_alert_title: '',
            reg_title_color: 'text-gray-900',
            reg_alert_msg: '',
            reg_alert_msg_id: 0,
        }
    },
    methods: {
        async signin(values) {
            try {
                this.loadDisplay=true;
                // console.log(values, this.loadDisplay)
                await this.$store.dispatch('login', values);
                if(this.$store.state.userLoggedIn) {
                  this.$router.push({ name: 'dashboard' })
                }
            } catch (error) {
              console.log(error)
              this.loadDisplay=false;
            }

        },
        async reload() {
          await this.$store.dispatch('reloadReg');
          this.reg_alert_msg = '';
        }
    },
    created() {
        this.reload();
        if(this.$store.state.userLoggedIn) {
          this.$router.push({ name: 'dashboard' })
        }
    }
}
</script>
