<template>
  <NAHeader />
  <div class="Auth">
    <SignIn />
  </div>
</template>

<script>
import NAHeader from '@/components/NAHeader.vue'
import SignIn from '@/components/Auth/SignIn.vue'

export default {
  name: 'HomeView',
  components: {
    NAHeader,
    SignIn
  }
}
</script>
