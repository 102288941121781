<template>
  <NAHeader />
  <div class="Auth">
    <SignUp />
  </div>
</template>

<script>
import NAHeader from '@/components/NAHeader.vue'
import SignUp from '@/components/Auth/SignUp.vue'

export default {
  name: 'HomeView',
  components: {
    NAHeader,
    SignUp
  }
}
</script>
